/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// https://www.gatsbyjs.org/docs/building-a-site-with-authentication/
import React from 'react';
import 'fomantic-ui-less/semantic.less';
import AppRoot from './src/components/AppRoot';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({element}) => <AppRoot>{element}</AppRoot>;
