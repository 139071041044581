import {flow, getParent, types} from 'mobx-state-tree';
import UserModel from './models/UserModel';
import logger from '../logger';
import getClient from '../apollo/client';

const AuthStore = types
  .model('AuthStore', {
    isAuthenticating: false,
    accessToken: types.maybeNull(types.string),
    user: types.maybeNull(UserModel),
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get isAuthenticated() {
      return self.accessToken && self.user;
    },
    get canAccessAdmin() {
      return self.isAuthenticated && (self.user.isAdmin || self.user.isStaff);
    },
  }))
  .actions(self => {
    const onAuthSuccess = auth => {
      const {accessToken, user} = auth;
      self.accessToken = accessToken;
      self.user = user;
      self.isAuthenticating = false;
    };
    const onLogout = () => {
      self.accessToken = null;
      self.user = null;
      self.isAuthenticating = false;
    };
    const onAuthError = e => {
      logger.error('Auth error: ', e);
      onLogout();
    };

    const afterCreate = flow(function* () {
      const client = getClient();
      self.isAuthenticating = true;
      try {
        const auth = yield client.reAuthenticate();
        onAuthSuccess(auth);
        return auth;
      } catch (e) {
        // Do nothing on error since we're only establishing the auth session
        // onAuthError(e);
        logger.warn('Error encountered while establishing session', e);
        return null;
      } finally {
        self.isAuthenticating = false;
      }
    });

    const login = flow(function* (usernameOrEmail, password) {
      const client = getClient();
      self.isAuthenticating = true;
      try {
        const auth = yield client.authenticate(usernameOrEmail, password);
        onAuthSuccess(auth);
        return auth;
      } catch (e) {
        onAuthError(e);
        throw e;
      } finally {
        self.isAuthenticating = false;
      }
    });

    const logout = flow(function* () {
      const client = getClient();
      let result = false;
      try {
        yield client.logout();
        result = true;
      } catch (e) {
        logger.warn('Error encountered during logout', e);
      } finally {
        onLogout();
      }

      return result;
    });

    return {
      afterCreate,
      login,
      logout,
    };
  });

export default AuthStore;
