import {createLogger, format} from 'winston';
import BrowserConsole from 'winston-transport-browserconsole';
import {logging} from './config';

const {enabled, level} = logging;

const NOOP = () => {};
const NOOP_LOGGER = {
  log: NOOP,
  debug: NOOP,
  trace: NOOP,
  info: NOOP,
  warn: NOOP,
  error: NOOP,
  fatal: NOOP,
};

let l;
if (enabled) {
  l = createLogger({
    // To see more detailed errors, change this to 'debug'
    level,
    format: format.combine(format.splat(), format.simple()),
    transports: [
      new BrowserConsole({
        format: format.simple(),
        level,
      }),
      // Uncomment to compare with default Console transport
      // new transports.Console({
      //     format: format.simple(),
      //     level,
      // }),
    ],
  });
} else {
  l = NOOP_LOGGER;
}

const logger = l;
export default logger;
