export const identity = Object.freeze({
  name: 'Austin Home Properties',
  brokerName: 'Nora Wall & Company',
});

export const isBrowser = typeof window !== 'undefined';
export const isDevelop =
  (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === 'development';

export const logging = Object.freeze({
  enabled: process.env.GATSBY_LOGGING_ENABLED === 'true' && isBrowser,
  level: 'debug',
});

export const providerKeys = Object.freeze({
  recaptcha: process.env.GATSBY_RECAPTCHA_KEY,
});

export const providerLinks = Object.freeze({
  homesearch: 'https://www.homesnap.com/Anjalicia-Ufomata',
});
