import {types} from 'mobx-state-tree';

const UserModel = types
  .model('UserModel', {
    id: types.identifier,
    accountType: types.enumeration(['ADMIN', 'STAFF', 'SYSTEM', 'USER']),
    firstName: types.string,
    lastName: types.string,
    username: types.string,
    email: types.maybeNull(types.string),
  })
  .views(self => ({
    get isAdmin() {
      return self.accountType === 'ADMIN';
    },
    get isStaff() {
      return self.accountType === 'STAFF';
    },
  }));

export default UserModel;
