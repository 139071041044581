import React, {createContext, useContext} from 'react';
import {getSnapshot, onSnapshot, types} from 'mobx-state-tree';
import AuthStore from './AuthStore';
import logger from '../logger';
import {isBrowser} from '../config';

export const RootStore = types.model('RootStore', {
  authStore: types.optional(AuthStore, {}),
});

// MST store only required in browser.  Mock default for SSR
let store = RootStore.create({});
if (isBrowser) {
  onSnapshot(store, snapshot => logger.debug('Snapshot: ', snapshot));
} else {
  store = getSnapshot(store);
}
const rootStore = store;
const StoreContext = createContext(null);
const StoreProvider = StoreContext.Provider;

export const WithRootStore = ({children}) => (
  <StoreProvider value={rootStore}>{children}</StoreProvider>
);

export const useRootStore = () => {
  const cachedStore = useContext(StoreContext);
  if (!cachedStore) {
    throw new Error('Root store not set in context');
  }

  return cachedStore;
};
