import React from 'react';
import {Helmet} from 'react-helmet';
import {ApolloProvider} from '@apollo/client';
import {WithRootStore} from '../stores/RootStore';
import {getApolloClient} from '../apollo/client';
import {mediaStyles, MediaContextProvider} from '../media';

const AppRoot = ({children}) => (
  <ApolloProvider client={getApolloClient()}>
    <WithRootStore>
      <Helmet>
        <style>{mediaStyles}</style>
      </Helmet>
      <MediaContextProvider>{children}</MediaContextProvider>
    </WithRootStore>
  </ApolloProvider>
);

export default AppRoot;
