import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Observable,
  gql,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {createUploadLink} from 'apollo-upload-client';
import fetch from 'isomorphic-fetch';
import {useMemo} from 'react';
import logger from '../logger';
import localStorage from '../localStorage';

const LOGIN = gql`
  mutation Login($login: String!, $password: String!) {
    login(login: $login, password: $password) {
      accessToken
      user {
        id
        accountType
        firstName
        lastName
        username
        email
        createdAt
        updatedAt
      }
    }
  }
`;

const REAUTH = gql`
  mutation RefreshToken {
    refreshToken {
      accessToken
      user {
        id
        accountType
        firstName
        lastName
        username
        email
        createdAt
        updatedAt
      }
    }
  }
`;

export const isGqlAuthError = error => {
  if (Array.isArray(error.graphQLErrors)) {
    return error.graphQLErrors.some(
      gqlError => gqlError.extensions?.code === 'UNAUTHENTICATED',
    );
  }

  return false;
};

const createApolloClient = client => {
  // https://www.apollographql.com/docs/react/v2/migrating/boost-migration/#advanced-migration
  // As opposed to using Apollo boost, we setup the client manually since we support file uploads
  // and need to construct our links manually:
  // https://github.com/jaydenseric/apollo-upload-client
  const cache = new InMemoryCache();

  const onErrorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) =>
        logger.info(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
      // sendToLoggingService(graphQLErrors);
    }
    if (networkError) {
      logger.info(`[Network error]: ${networkError}`);
      // logoutUser();
    }
  });

  const request = operation => {
    if (!client.auth) return;
    const {accessToken} = client.auth;
    operation.setContext({
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    });
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(op => request(op))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      }),
  );

  const uploadFileLink = createUploadLink({
    uri: `${process.env.GATSBY_API_URL}/graphql`,
    fetch,
  });

  return new ApolloClient({
    link: ApolloLink.from([onErrorLink, requestLink, uploadFileLink]),
    cache,
  });
};

class Client {
  constructor() {
    this.auth = null;
    this.apolloClient = createApolloClient(this);

    (async () => {
      try {
        this.auth = await localStorage.getAuth();
      } catch (e) {
        logger.error('Error loading Apollo wrapper client context', e);
      }
    })();
  }

  get authenticated() {
    return !!this.auth;
  }

  get user() {
    return this.auth?.user;
  }

  get userCanAccessAdmin() {
    return ['ADMIN', 'STAFF'].includes(this.user?.accountType);
  }

  async reAuthenticate() {
    // Need existing token to refresh the token
    const cachedAuth = await localStorage.getAuth();
    if (!cachedAuth) return null;
    // Set auth prior to refresh token request
    this.auth = cachedAuth;

    try {
      const {
        data: {refreshToken: auth},
      } = await this.apolloClient.mutate({
        mutation: REAUTH,
        fetchPolicy: 'no-cache',
      });

      this.auth = auth;
      return localStorage.setAuth(auth);
    } catch (e) {
      return this.handleError(e, 'reAuthenticate');
    }
  }

  async authenticate(usernameOrEmail, password) {
    try {
      const {
        data: {login: auth},
      } = await this.apolloClient.mutate({
        mutation: LOGIN,
        variables: {login: usernameOrEmail, password},
        fetchPolicy: 'no-cache',
      });

      this.auth = auth;
      return localStorage.setAuth(auth);
    } catch (e) {
      return this.handleError(e);
    }
  }

  // isAuthorized(accountTypes) {
  //   if (!accountTypes) return true;
  //   const accountTypesList = Array.isArray(accountTypes)
  //     ? accountTypes
  //     : [accountTypes];
  //   return accountTypesList.includes(this.user?.accountType);
  // }

  async logout() {
    try {
      await localStorage.removeAuth();
      this.reset();
    } catch (e) {
      await this.handleError(e, 'logout');
    }
  }

  reset() {
    this.auth = null;
  }

  async handleError(error, type) {
    if (isGqlAuthError(error) /* || error.code === 403 */) {
      await localStorage.removeAuth();
      this.reset();

      if (['logout', 'reAuthenticate'].includes(type)) {
        return; // Don't error
      }
    }

    throw error;
  }
}

let client;
const getClient = () => {
  if (client) return client;
  client = new Client();
  return client;
};

export const getApolloClient = () => getClient().apolloClient;

export const useClient = () => useMemo(getClient, []);

export default getClient;
