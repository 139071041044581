import localforage from 'localforage';

localforage.config({
  // driver      : localforage.WEBSQL, // Force WebSQL; same as using setDriver()
  name: 'ahp', // 'localforage',
  // version     : 1.0,
  // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
  // storeName   : 'keyvaluepairs', // Should be alphanumeric, with underscores.
  // description : 'some description'
});

const AUTH_STORAGE_KEY = 'ahp-auth';

export default {
  setAuth: async auth => localforage.setItem(AUTH_STORAGE_KEY, auth),

  getAuth: async () =>
    // Support read from browser url? i.e. window.location?
    localforage.getItem(AUTH_STORAGE_KEY),

  removeAuth: async () => localforage.removeItem(AUTH_STORAGE_KEY),

  getItem: async key => localforage.getItem(key),

  setItem: async (key, value) => localforage.setItem(key, value),

  removeItem: async key => localforage.removeItem(key),
};
